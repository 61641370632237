import Navigation from './navigation/index';
import SwiperInit from './swiper';
import Alerts from './alerts';

(() => {
  document.addEventListener('turbo:load', () => {
    Navigation();
    SwiperInit();
    Alerts();
	})
})();