import "@hotwired/turbo-rails";
import "./controllers";
import "@rails/actiontext";
import * as modules from './modules/index';

if (navigator.serviceWorker) {
  navigator.serviceWorker.register('/service-worker.js', { scope: './' })
                         .then(function(registration) {
                           console.log('[Companion]', 'Service worker registered!')
                           console.log(registration)
                         })
}
