import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="alerts"
export default class extends Controller {
  connect() {
    const container = this.element;
    let alertsLow = document.querySelector(".alert-low");
    let alertsMed = document.querySelector(".alert-medium");
    let alertsHigh = document.querySelector(".alert-high");

    if(alertsHigh){
      container.style.backgroundColor = 'var(--alert-high)';      
    } else if(alertsMed){
      container.style.backgroundColor = 'var(--alert-medium)';     
    } else if (alertsLow) {
      container.style.backgroundColor = 'var(--alert-normal)';
    } else {
      container.style.backgroundColor = 'var(--red)';
      container.style.paddingTop = "0px";
    }

  }
}
