export default () => {

  const alertTitle = document.querySelector('.alerts-title');
  const alertList = document.querySelector('.alerts-list');

  if(alertTitle){
    alertTitle.addEventListener('click', () => {
      alertList.classList.toggle('open');
    })
  }


};